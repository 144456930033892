import React, { useState } from "react"
import { initializeApp } from "firebase/app"
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  deleteUser,
} from "firebase/auth"
import {
  getFirestore,
  getDoc,
  getDocs,
  doc,
  deleteDoc,
  deleteField,
  updateDoc,
  collection,
} from "firebase/firestore"
import { Button, Spin, message } from "antd"
import { CheckCircleTwoTone } from "@ant-design/icons"
import Empty from "../empty"
import googleLogo from "../../images/google.svg"
import PhoneNumberSignIn from "./phone-number-signin"
import "../../styles/account-delete.sass"

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

const UI_STATUS = {
  INITIAL: "INITIAL",
  PHONE_NUMBER_INPUT: "PHONE_NUMBER_INPUT",
  VERIFYING: "VERIFYING",
  DELETING: "DELETING",
  SUCCESS: "SUCCESS",
}

const AccountDelete = ({ app }) => {
  const [uiStatus, setUIStatus] = useState(UI_STATUS.INITIAL)

  const deleteAccount = async uid => {
    try {
      setUIStatus(UI_STATUS.VERIFYING)
      const docRef = doc(db, "users", uid)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists && docSnap.exists()) {
        const docData = docSnap.data()
        let codeKey = ""
        let isAssociatedWithOtherApp = false
        if (app === "Magnus") {
          codeKey = "activationCode"
          isAssociatedWithOtherApp = !!docData?.roleCode?.trim?.()
        } else if (app === "Toolbox for Magnus") {
          codeKey = "roleCode"
          isAssociatedWithOtherApp = !!docData?.activationCode?.trim?.()
        }

        if (!docData?.[codeKey]?.trim?.()) {
          message.error(`This account is not associated with the ${app} App.`)
          setUIStatus(UI_STATUS.INITIAL)
          return
        }

        setUIStatus(UI_STATUS.DELETING)
        if (isAssociatedWithOtherApp) {
          await updateDoc(docRef, {
            [codeKey]: deleteField(),
          })
        } else {
          const currentUser = auth.currentUser
          await deleteUser(currentUser)
          await deleteDoc(docRef)
        }

        setUIStatus(UI_STATUS.SUCCESS)
      } else {
        message.error("Account not exists")
        if (auth.currentUser) {
          await deleteUser(auth.currentUser)
        }
        setUIStatus(UI_STATUS.INITIAL)
      }
    } catch (error) {
      console.log(error)
      setUIStatus(UI_STATUS.INITIAL)
      message.error("Something went wrong. Please try again.")
    }
  }

  const handleGoogleSignInClick = async () => {
    try {
      setUIStatus(UI_STATUS.VERIFYING)
      const credential = await signInWithPopup(auth, new GoogleAuthProvider())
      const { user } = credential
      await deleteAccount(user.uid)
    } catch (error) {
      console.log({ error })
      setUIStatus(UI_STATUS.INITIAL)
      message.error("Something went wrong. Please try again.")
    }
  }

  const fetchCountries = async () => {
    const docsSnap = await getDocs(collection(db, "countries"))
    return docsSnap.docs.map(doc => doc.id)
  }

  const renderUI = () => {
    const title = (
      <div className="title">
        Login using your <span className="app-name">{app} App</span> account to
        start the account deletion process, which will commence upon successful
        authentication. <br /> (Note: We only collect your name and email/phone
        during the account creation, and these details will be promptly
        deleted.)
      </div>
    )

    if (uiStatus === UI_STATUS.INITIAL) {
      return (
        <>
          {title}
          <div className="initial-options">
            <Button
              shape="round"
              className="option-btn"
              onClick={handleGoogleSignInClick}
            >
              <img src={googleLogo} alt="google" className="google-icon" />
              Sign in with Google
            </Button>
            <Button
              shape="round"
              className="option-btn"
              onClick={() => {
                setUIStatus(UI_STATUS.PHONE_NUMBER_INPUT)
              }}
            >
              Sign in with Phone Number
            </Button>
          </div>
        </>
      )
    } else if (uiStatus === UI_STATUS.PHONE_NUMBER_INPUT) {
      return (
        <>
          {title}
          <PhoneNumberSignIn
            fetchCountries={fetchCountries}
            auth={auth}
            onVerified={uid => {
              deleteAccount(uid)
            }}
            resetToInitialState={() => {
              setUIStatus(UI_STATUS.INITIAL)
            }}
          />
        </>
      )
    } else if (uiStatus === UI_STATUS.DELETING) {
      return (
        <>
          {title}
          <div className="loading-container">
            <Spin size="large" tip="Deleting Account" />
          </div>
        </>
      )
    } else if (uiStatus === UI_STATUS.VERIFYING) {
      return (
        <>
          {title}
          <div className="loading-container">
            <Spin size="large" tip="Verifying Account" />
          </div>
        </>
      )
    } else if (uiStatus === UI_STATUS.SUCCESS) {
      return (
        <div className="delete-ack-container">
          <CheckCircleTwoTone twoToneColor="#52c41a" className="success-icon" />
          <div className="delete-success-title">
            <span className="app-name">{app} App</span> Account Deleted
            Successfully!
          </div>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              window.location.href = "/"
            }}
          >
            Done
          </Button>
        </div>
      )
    }
    return null
  }

  // render
  if (app !== "Magnus" && app !== "Toolbox for Magnus") {
    return <Empty />
  }

  return <div className="delete-container">{renderUI()}</div>
}

export default AccountDelete
