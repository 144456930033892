import React, { useState, useEffect } from "react"
import { Button, Input, Select, message } from "antd"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import {
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input"
import "../../styles/phone-number-signin.sass"

const PhoneNumberSignIn = ({
  fetchCountries,
  auth,
  onVerified,
  resetToInitialState,
}) => {
  const [isCountriesLoading, setIsCountriesLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [country, setCountry] = useState("")
  const [countries, setCountries] = useState([])
  const [isOtpSentSuccess, setIsOtpSentSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [otp, setOtp] = useState("")

  const onOTPChange = e => {
    setOtp(e.target.value)
  }

  const sendOTP = async () => {
    try {
      const phoneNo = `${country}${phoneNumber}`
      if (!isValidPhoneNumber(phoneNo)) {
        message.error("Invalid phone number")
        return
      }

      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      )

      setIsLoading(true)
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNo,
        window.recaptchaVerifier
      )
      setIsLoading(false)
      setIsOtpSentSuccess(true)
      window.confirmationResult = confirmationResult
    } catch (error) {
      console.log(error)
      window.confirmationResult = null
      setIsLoading(false)
      setIsOtpSentSuccess(false)
      message.error("Failed to send OTP")
    }
  }

  const onPhoneNumberChange = e => {
    setPhoneNumber(e.target.value)
  }

  const verifyOTP = async () => {
    try {
      if (otp.length !== 6) {
        message.error("Please input a valid OTP")
        return
      }
      if (!window.confirmationResult) {
        message.error("Please send OTP")
        return
      }
      setIsLoading(true)
      await window.confirmationResult.confirm(otp).then(result => {
        setIsLoading(false)
        const uid = result?.user?.uid
        onVerified(uid)
      })
    } catch (error) {
      setIsLoading(false)
      console.log({ error })
      if (error?.code === "auth/invalid-verification-code") {
        message.error("Invalid OTP")
      } else {
        message.error("Failed to verify OTP")
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsCountriesLoading(true)
        const countriesData = await fetchCountries()
        setCountries(countriesData)

        const data = await fetch(
          "https://us-central1-magnusselfinstall.cloudfunctions.net/geolocation",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then(res => res.json())

        let countryFromResponse = data?.country
        if (countryFromResponse) {
          countryFromResponse = countriesData.find(
            x => x === countryFromResponse
          )
            ? countryFromResponse
            : "IE"
          setCountry(`+${getCountryCallingCode(countryFromResponse)}`)
        }
      } catch (err) {
        message.error("Failed to load country")
      } finally {
        setIsCountriesLoading(false)
      }
    })()
  }, [])

  return (
    <>
      <div className="content">
        {isOtpSentSuccess ? (
          <div className="form-item-container">
            <div className="label">Enter your One-time password (OTP)</div>
            <div className="phone-number-container">
              <Input
                className="phone-number-input"
                value={otp}
                onChange={onOTPChange}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="form-item-container">
              <div className="label">Phone Number</div>
              <div className="phone-number-container">
                <Select
                  className="country-select"
                  bordered={false}
                  value={country}
                  onChange={setCountry}
                  loading={isCountriesLoading}
                  disabled={isCountriesLoading}
                >
                  {countries.map(item => {
                    const code = `+${getCountryCallingCode(item)}`
                    return (
                      <Select.Option key={item} value={code}>
                        <img
                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item}.svg`}
                          alt="flag"
                          width="25"
                        />{" "}
                        <span className="option-country-code">{code}</span>
                      </Select.Option>
                    )
                  })}
                </Select>
                <Input
                  className="phone-number-input"
                  value={phoneNumber}
                  onChange={onPhoneNumberChange}
                />
              </div>
            </div>
          </>
        )}
        <div className="buttons-container">
          <Button
            type="primary"
            className="otp-button"
            size="large"
            shape="round"
            onClick={isOtpSentSuccess ? verifyOTP : sendOTP}
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading || isCountriesLoading}
          >
            {isOtpSentSuccess ? "Verify and Delete" : "Send OTP"}
          </Button>
          <Button
            type="round"
            onClick={resetToInitialState}
            className="back-button"
          >
            Back
          </Button>
        </div>
        {!isOtpSentSuccess ? <div id="recaptcha-container" /> : null}
      </div>
    </>
  )
}

export default PhoneNumberSignIn
